/*
* Buttons
*/

// Button General Styles

.button {
	position: relative;
	overflow: hidden;
	display: inline-block;
	@include btn-size(10px, 28px, 13px, $input-btn-line-height);
	border: 2px solid;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	letter-spacing: $btn-letter-spacing;
	text-transform: $btn-text-transform;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	user-select: none;
	transition: 250ms all ease-in-out;
}

.button-block {
	display: block;
	width: 100%;
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.button {
		@include btn-size($input-btn-padding-y, $input-btn-padding-x, $input-btn-font-size, $input-btn-line-height);
	}
	
	.button-less {
		padding-left: 30px;
		padding-right: 30px;
	}
	
	.button-lesser {
		padding-left: 20px;
		padding-right: 20px;
	}
}

//
// Appearance style
// 

.button-default {
	@include btn-variant($gray-300, $gray-700, $gray-700, $white, $primary, $primary);
}

.button-gray-100 {
	@include btn-variant($gray-800, $gray-100, $gray-100, $gray-800, darken($gray-100, 5%), darken($gray-100, 5%));
}

.button-gray-600 {
	@include btn-variant($white, $gray-600, $gray-600, $white, $primary-light, $primary-light);
}
.button-gray-700 {
	@include btn-variant($white, $gray-700, $gray-700, $white, $primary-light, $primary-light);
}

.button-primary {
	@include btn-variant($white, $primary, $primary, $gray-800, $secondary, $secondary);
}

.button-primary-light {
	@include btn-variant($white, $primary-light, $primary-light, $gray-800, $secondary-light, $secondary-light);
}

.button-secondary {
	@include btn-variant($gray-800, $secondary, $secondary, $white, $primary-light, $primary-light);
}

.button-secondary-light {
	@include btn-variant($gray-800, $secondary-light, $secondary-light, $white, $primary-light, $primary-light);
}

// Outline
.button-default-outline {
	border-width: 3px	;
	@include btn-variant($gray-800, transparent, rgba($black, .2), $white, $primary-light, $primary-light);
}

.button-ghost {
	padding: 12px 30px;
	color: $gray-800;
	border: none;
	background-color: $white;

	&:hover {
		color: $white;
		background: $primary-light;
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding: 15px 52px;
	}
}

// Social
.button-facebook {
	@include btn-variant($white, $facebook, $facebook, $white, $facebook-darken, $facebook-darken);
}

.button-twitter {
	@include btn-variant($white, $twitter, $twitter, $white, $twitter-darken, $twitter-darken);
}

.button-google {
	@include btn-variant($white, $google, $google, $white, $google-darken, $google-darken);
}

// Btn with shadow
.button-shadow {
	box-shadow: 0 9px 21px 0 rgba($gray-300, 0.35);

	&:hover {
		box-shadow: 0 9px 10px 0 rgba($gray-300, 0.15);
	}

	&:focus,
	&:active {
		box-shadow: none;
	}
}

//
// Button effects
//
.button-ujarak {
	position: relative;
	z-index: 0;
	transition: background .4s, border-color .4s, color .4s;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $primary;
		z-index: -1;
		opacity: 0;
		transform: scale3d(0.7, 1, 1);
		transition: transform 0.42s, opacity 0.42s;
		border-radius: inherit;
	}

	&,
	&::before {
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}

	&:hover {
		transition: background .4s .4s, border-color .4s 0s, color .2s 0s;
		&::before {
			opacity: 1;
			transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		}
	}
}

//
// Button Sizes
// 

.button-xs {
	padding: 8px 17px;
}

.button-sm {
	@include btn-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, 12px, $input-btn-line-height-sm);
}

.button-lg {
	@include btn-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, 16px, $input-btn-line-height-lg);
}

.button-xl {
	@include btn-size(16px, 50px, 18px, 28px);

	@include media-breakpoint-up(lg) {
		padding: 20px 80px;
	}
}

//
// Button Shapes
// 

.button-circle {
	border-radius: 30px;
}

.button-round-1 {
	border-radius: 5px;
}

.button-round-2 {
	border-radius: 10px;
}

//
// Button Icon styles
//
.button {
	
	&.button-is-icon {
		padding: 5px 19px;
		font-size: 24px;
	}
	
	&.button-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		vertical-align: middle;

		.icon {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			color: inherit;
			font-size: 1.55em;
			line-height: 1em;
		}

		&-left {
			.icon {
				padding-right: 11px;
			}
		}

		&-right {
			flex-direction: row-reverse;
			.icon {
				padding-left: 11px;
			}
		}

		&.button-link {
			.icon {
				top: 5px;
				font-size: 1em;
			}
		}

		&.button-xs {
			.icon {
				top: .05em;
				font-size: 1.2em;
				padding-right: 8px;
			}

			.button-icon-right {
				padding-left: 8px;
			}
		}
	}
}

//
// Bootstrap buttons
//

.btn-primary {
	border-radius: 3px;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	letter-spacing: .05em;
	text-transform: uppercase;
	transition: .33s;

	&,
	&:active,
	&:focus {
		color: $white;
		background: $primary;
		border-color: $primary;
	}

	&:hover {
		color: $white;
		background: $black;
		border-color: $black;
	}
}

//
// Button group
//
.button-group {
	> li {
		display: inline-block;
	}
	
	@include spacing(15px);
}


* + .button { margin-top: 10px; }
* + .button-group { margin-top: 15px; }
// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .button { margin-top: 30px; }
	* + .button-group { margin-top: 30px; }
}