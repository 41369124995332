/*
 * Video
*/

.video {
	position: relative;
	z-index: 1;
}

.video-link {
	position: absolute;
	z-index: 1;
	width: 90px;
	height: 90px;
	line-height: 90px;
	font-size: 70px;
	text-align: center;
	top: 50%;
	left: 50%;
	

	border-radius: 50%;
	transform: translate(-50%, -50%) scale(1);
	transition: .3s ease-out all;
	
	.icon {
		font-size: inherit;
	}
	
	&, &:focus, &:active, &:hover, &.active {
		color: $white;
		background-color: rgba($white, .47);
	}
	
	&:hover {
	  transform: translate(-50%, -50%) scale(1.1);
	}
}