/*
* Offsets
*/

// Elements offset
* + p {
	margin-top: 15px;
}

// Headings
h1 + h4 { margin-top: 10px; }

// Headings + Paragraph
h2 + p,
.heading-2 + p {
	margin-top: 12px;
}

h3 + p { margin-top: 15px; } //

@include media-breakpoint-up(xl) {
	h3 + p { margin-top: 25px; } //
}

// Paragraph + Headings
p + h2,
p + .heading-2 {
	margin-top: 15px;
}

// Tags + Tags
p + p {
	margin-top: 12px;
}

img + p {
	margin-top: 15px;
}

h3 + img {
	margin-top: 42px;
}

// Classes
* + .row {
	margin-top: 40px;
}

* + .big {
	margin-top: 20px;
}

* + .text-block {
	margin-top: 30px;
}


* + .offset-top-1 {
	margin-top: 3px;
}

* + .offset-top-2 {
	margin-top: 30px;
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .offset-top-2 {
		margin-top: 90px;
	}
}

// Classes + Tags
.row + h3,
.row + .heading-3 {
	margin-top: 80px;
}

// Classes + Classes
.container + .container {
	margin-top: 35px;
}

.row + .row {
	margin-top: 35px;
}

// Media offsets
@include media-breakpoint-up(xl) {
	* + .rd-form {
		margin-top: 30px;
	}

	.container + .container {
		margin-top: 60px;
	}
	.row + .row {
		margin-top: 60px;
	}
}

// Range spacing
.row-0 {
	@include grid-offset(0px);
}

.row-15 {
	@include grid-offset(15px);
}

.row-20 {
	@include grid-offset(20px);
}

.row-30 {
	@include grid-offset(30px);
}

.row-50 {
	@include grid-offset(50px);
}

@include media-breakpoint-up(lg) {
	.row-lg-30 {
		@include grid-offset(30px);
	}
	.row-lg-50 {
		@include grid-offset(50px);
	}
	.row-lg-60 {
		@include grid-offset(60px);
	}

}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.row-xl-90 {
		@include grid-offset(90px);
	}
}
