/*
*
* RD Navbar Sidebar
*/

$rd-navbar-sidebar-width: map-get($container-max-widths, xl);

.rd-navbar-sidebar-linked {
	.page-header-sidebar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
}

.rd-navbar-sidebar {
	display: block !important;
	background: rgba($gray-900, .35);
	
	.rd-navbar-main-outer {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}
	
	// RD Navbar Main
	.rd-navbar-main {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 14px 60px 14px 0;
		max-width: $rd-navbar-sidebar-width;
		margin-left: auto;
		margin-right: auto;
		transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	}
	
	.rd-navbar-main-element {
		display: flex;
		align-items: center;
		
		> * + * {
			margin-left: 20px;
		}
	}
	
	// RD Navbar Toggle
	.rd-navbar-toggles {
		z-index: $zindex-layout-panel + 2;
		position: absolute;
		right: 0;
		top: 50%;
		display: block;
		padding: 0;
		color: $white;
		background-color: transparent;
		box-shadow: none;
		border: none;
		cursor: pointer;
		transform: translateY(-50%);
		
		&:before, &:after {
			transition: .3s ease-out opacity, .3s ease-out transform;
		}
		
		&:before {
			font-size: 27px;
			content: "\e069";
			font-family: 'fl-bigmug-line';
			transform: scale(1);
		}
		
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			font-size: 30px;
			content: '\e00a';
			font-family: "fl-budicons-free";
			opacity: 0;
		}
		
		&.active {
			&:before {
				opacity: 0;
				transform: scale(0);
			}
			
			&:after {
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}
		}
		
		span {
		}
	}
	
	// RD Navbar Panel
	.rd-navbar-panel {
		min-width: 200px;
		text-align: center;
	}
	
	// RD Navbar Brand
	.rd-navbar-brand {
		img {
			width: auto;
			height: auto;
			max-width: 200px;
			max-height: 200px;
		}
	}
	
	// RD Navbar Nav Wrap Inner
	.rd-navbar-nav-wrap {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: $zindex-layout-panel + 1;
		width: 450px;
		padding: 86px 0 0 0;
		background: $gray-600;
		transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
		transform: translateX(100%);
		box-shadow: 0 0 13px 0 rgba($gray-300, .16);
		
		&.active {
			transition-delay: .1s;
			transform: translateX(0);
		}
	}
	
	.rd-navbar-nav {
		height: calc(100vh - 100px);
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		border-top: 1px solid #545454;
	}
	
	.rd-nav-item {
		position: relative;
		display: block;
		padding: 20px 30px 20px 45px;
		
		&.opened,
		&.active {
			.rd-navbar-submenu-toggle,
			.rd-nav-link {
				color: $secondary-light;
			}
		}
		
		.rd-nav-link,
		> .rd-navbar-submenu-toggle {
			font-size: 18px;
		}
		
		+ .rd-nav-item {
			border-top: 1px solid #545454;
		}
		
		&:last-child {
			border-bottom: 1px solid #545454;
			margin-bottom: 30px;
		}
	}
	
	.rd-nav-link {
		max-width: calc(100% - 30px);
		word-break: break-all;
		font-family: $font-family-sec;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.4;
		text-transform: none;
		color: $white;
		
		&:hover {
			color: $secondary-light;
		}
	}
	
	// RD Navbar Submenu
	.rd-navbar-submenu {
		.opened > .rd-navbar-submenu-toggle {
			&::after {
				transform: rotate(180deg);
			}
		}
		
		> .rd-navbar-dropdown,
		> .rd-navbar-megamenu {
			display: none;
			opacity: 0;
			visibility: hidden;
			will-change: opacity, visibility;
			transition: opacity .2s;
		}
		
		&.opened {
			> .rd-navbar-dropdown,
			> .rd-navbar-megamenu {
				display: block;
				opacity: 1;
				visibility: visible;
			}
		}
		
		> .rd-navbar-submenu {
			> .rd-navbar-dropdown,
			> .rd-navbar-megamenu {
				transform: translateY(30px);
			}
			
			&.opened {
				> .rd-navbar-dropdown,
				> .rd-navbar-megamenu {
					transform: translateY(0);
				}
			}
			
			.rd-navbar-submenu {
				> .rd-navbar-dropdown {
					transform: translateX(-20px);
				}
				
				&.opened {
					> .rd-navbar-dropdown {
						transform: translateX(0);
					}
				}
			}
		}
	}
	
	.rd-dropdown-item {
		.rd-dropdown-link {
			font-size: 16px;
			@include link($white, $primary-light);
			
			&:before {
				top: 1px;
				color: $primary-light;
			}
		}
	}
	
	.rd-megamenu-list {
		.rd-megamenu-list-link {
			font-size: 16px;
			@include link($white, $primary-light);
			
			&:before {
				top: 1px;
				color: $primary-light;
			}
		}
	}
	
	// RD Navbar Submenu Toggle
	.rd-nav-item > .rd-navbar-submenu-toggle,
	.rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle {
		position: absolute;
		top: 0;
		right: 0;
		padding-top: inherit;
		padding-right: inherit;
		padding-left: 10px;
		margin-bottom: inherit;
		display: inline-block;
		width: 30px;
		margin-left: 5px;
		text-align: center;
		cursor: pointer;
		line-height: 1;
		color: #898989;
		
		&:hover {
			color: $secondary-light;
		}
		
		&::after {
			content: '\f107';
			position: relative;
			display: inline-block;
			font-family: $fa;
			font-size: 24px;
			line-height: inherit;
			text-align: center;
			vertical-align: middle;
			transition: 0.4s all ease;
			z-index: 2;
			will-change: transform;
		}
	}
	
	.rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle {
		font-size: 14px;
		color: $gray-300;
		margin-left: 7px;
	}
	
	// RD Navbar Submenu
	.rd-menu {
		margin-top: 31px;
	}
	
	.rd-navbar-dropdown,
	.rd-megamenu-list {
		font-size: 14px;
		@include submenu-with-arrow;
	}
	
	// RD Navbar Megamenu
	.rd-navbar-megamenu {
		max-width: 450px;
		@include groupY(30px);
		
		> li {
			display: inline-block;
			vertical-align: top;
			width: 45%;
		}
	}
	
	.rd-megamenu-title {
		display: none;
	}
	
	.rd-megamenu-list {
		margin-top: 0;
		
		> li + li {
			margin-top: 10px;
		}
	}
	
	// RD Navbar Dropdown
	.rd-navbar-dropdown {
		.rd-navbar--has-dropdown {
			> a {
				padding-right: 0;
				&::before {
					display: none;
				}
				&:hover {
					padding-left: 0;
				}
			}
			
			&.focus > a {
				padding-left: 0;
			}
		}
		
		.rd-navbar--has-dropdown.opened > .rd-navbar-submenu-toggle {
			color: $primary;
			
			&::after {
				top: 1px;
			}
		}
		
		> li + li {
			margin-top: 10px;
		}
	}
	
	.rd-nav-item > .rd-navbar-dropdown {
		margin-top: 25px;
		.rd-navbar-dropdown {
			margin-top: 10px;
			padding-left: 15px;
			
			> li > a {
				font-size: 12px;
			}
		}
	}
	
	@include media-breakpoint-up(xl) {
		.rd-navbar-megamenu {
			@include groupY(30px);
		}
		
		.rd-navbar-dropdown,
		.rd-megamenu-list {
			> li + li {
				margin-top: 13px;
			}
		}
	}
	
	@media (min-width: 1200px) and (max-width: 1799px) {
		.rd-navbar-toggles {
			position: fixed;
			top: 45px;
			right: 20px;
			transition: .3s ease all;
			&.active {
				right: 320px;
				transition: .8s ease all;
			}
		}
		
		.rd-navbar-nav-wrap {
			width: 400px;
			left: auto;
			right: 0;
		}
	}

	
	@media (min-width: 1800px) {
		.rd-navbar-nav-wrap {
			width: auto;
			left: calc(50% + 600px - 80px);
		}
	}
	
	// States
	&.rd-navbar--is-clone {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		transform: translateY(-101%);
		
		&.rd-navbar--is-stuck {
			transform: translateY(0);
		}
	}
	
	&.rd-navbar--is-stuck {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1000;
		background-color: $gray-600;
		box-shadow: 0 2px 10px 1px rgba($black, .1);
		
		.rd-navbar-brand {
			img {
				max-width: 90px;
			}
		}
		
		.rd-navbar-main {
			//padding-top: 12px;
			//padding-bottom: 12px;
		}
		
		@media (min-width: 1200px) and (max-width: 1799px) {
			.rd-navbar-toggles {
				top: 40px;
			}
		}
	}
}
