/*
* Typography
*/

// Body
body {
	font-family: $font-family-base;
	font-size: $font-size-body;
	line-height: $line-height-base;
	font-weight: $font-weight-base;
	color: $body-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

// Headings
#{headings()} {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: $headings-font-weight;
	color: $headings-color;
	
	a {
		color: inherit;
		
		&:hover {
			color: $primary;
		}
	}
}

h1,
.heading-1 {
	font-size: 34px;
	line-height: 1.3;
	letter-spacing: 0.065em;
	text-transform: uppercase;
	
	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2,
.heading-2 {
	font-size: 30px;
	line-height: 1.5;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	
	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3,
.heading-3 {
	font-size: 24px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	
	@include media-breakpoint-up(xl) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
	
	.small {
		font-size: 0.833em;
		font-weight: 700;
	}
}

h4,
.heading-4 {
	font-size: 19px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	
	@include media-breakpoint-up(xl) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.heading-5 {
	font-size: $h5-font-size;
	line-height: $h5-line-height;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

h6,
.heading-6 {
	font-family: $font-family-base;
	font-size: $h6-font-size;
	line-height: $h6-line-height;
	letter-spacing: 0.05em;
}

// Emphasis
small,
.small {
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-sm;
}

mark,
.mark {
	padding: 3px 5px;
	color: $white;
	background: $primary;
}

// Big text
.big {
	font-size: $font-size-lg;
	line-height: $line-height-lg;
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
	font-weight: $lead-font-weight;
}

code {
	padding: $code-padding-y $code-padding-x;
	border-radius: $border-radius-sm;
	font-size: $code-font-size;
	color: $code-color;
	background: $code-bg;
}

// Paragraph
p {
	[data-toggle='tooltip'] {
		padding-left: .25em;
		padding-right: .25em;
		color: $primary;
	}
	
	[style*='max-width'] {
		display: inline-block;
	}
}

// Selection
::selection {
	background: $primary;
	color: $white;
}

::-moz-selection {
	background: $primary;
	color: $white;
}

/*
 * Heading Decorating
*/

.heading-decoration {
	position: relative;
	overflow: hidden;
	text-transform: uppercase;
	
	span {
		position: relative;
		max-width: 480px;
		display: inline-block;
		padding: 0 15px;
		
		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			width: 100vw;
			height: 2px;
			background-color: $gray-1;
			transform: translateY(-50%);
		}
		&:before {
			right: 100%;
		}
		&:after {
			left: 100%;
		}
	}
	
	&-modern {
		span {
			&:before {
				right: calc(100% - 15px);
			}
			&:after {
				left: calc(100% - 15px);
			}
		}
	}
	
	.context-dark & {
		span {
			&:before, &:after {
				background-color: rgba($white, .35);
			}
		}
	}
	
	@media (min-width: 440px) {
		&-modern {
			span {
				&:before {
					right: calc(100% - 30px);
				}
				&:after {
					left: calc(100% - 30px);
				}
			}
		}
	}
	
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		span {
			padding: 0 30px;
		}
	}
}

[data-x-mode="true"] {
	.preloader {
		opacity: 0;
		visibility: hidden;
	}
}

.bg-cover {
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	background-size: cover;
}