/*
 * Projects Info
*/
.project-info {
	padding: 15px 30px;
	border: 4px solid $gray-1;
}

.project-info-title {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.project-info-value {
	font-family: $font-family-sec;
	font-size: 60px;
	line-height: 1;
	font-weight: 700;
	color: $secondary;
}

.project-info-value-metrics {
	position: relative;
	padding-right: .4em;
	font-size: .6em;
	text-transform: uppercase;
	span {
		position: absolute;
		right: 0;
		top: .5em;
		font-size: .5em;
		text-transform: none;
	}
}

.project-info-item {
	padding: 35px 0 30px;
}

.project-info-item + .project-info-item {
	border-top: 1px solid $gray-1;
}

* + .project-info {
	margin-top: 40px;
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.project-info-wrap {
		padding-top: 95px;
	}
}

/*
 * Project
*/
.project-data {
	position: relative;
	z-index: 1;
	display: block;
	
	&:before {
	  content: '';
		z-index: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($gray-600, .39);
		transition: .3s ease-out opacity;
		pointer-events: none;
	}
}

.project-data-content {
	position: absolute;
	z-index: 2;
	top: 15px;
	bottom: 15px;
	left: 15px;
	right: 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 15px;
	color: $white;
	background-color: rgba($gray-900, .33);
	border: 2px solid $white;
}


[data-x-mode="true"] .project-data-content {
	pointer-events: none;
	& > * {
		pointer-events: auto;
	}
}

.project-data-title { color: $white; }
.project-data-text { width: 100%; }

* + .project-data-text { margin-top: 12px; }

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	
	.project-data-content {
		padding: 15px;
	}
	
	.desktop {
		.project-data {
			&:before {
				opacity: 0;
			}
			
			&:hover {
				&:before {
					opacity: 1;
				}
				
				.project-data-content {
					opacity: 1;
				}
			}
		}
		
		.project-data-content {
			opacity: 0;
			transition: .3s ease-out opacity;
		}
	}
}

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
	.project-data-content {
		top: 20px;
		bottom: 20px;
		left: 20px;
		right: 20px;
		padding: 30px 40px;
	}
}

/*
 * Project Single Block
*/
.project-single-block {
	&-list {
		max-width: 403px;
		
		.icon {
			margin-right: 8px;
			font-size: 20px;
			color: $primary;
		}
		
		dl {
			padding-top: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid #ebebeb;
		}
		
		dt, dd {
			display: inline-flex;
			align-items: center;
			font-family: $font-family-sec;
			font-size: 18px;
		}
		dt {
			margin-right: 12px;
			color: $gray-800;
			&:after {
			  content: ':';
			}
		}
		
		* + & { margin-top: 5px; }
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
	  &-list {
			dl {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}
	}
}