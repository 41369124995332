/*
*
* Circle Progress Bars
*/

.progress-bar-circle {
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;

	canvas {
		vertical-align: middle;
	}

	span {
		position: absolute;
		top: 50%;
		left: 51%;
		font-size: 36px;
		line-height: 36px;
		font-family: $font-family-sec;
		font-weight: 500;
		transform: translate(-50%, -50%);
		color: $gray-800;

		&::after {
			content: "%";
		}
	}
}

.progress-bar-circle-title {
	font-size: 14px;
	letter-spacing: .05em;
	text-transform: uppercase;
	color: $gray-500;
}

* + .progress-bar-circle-title {
	margin-top: 12px;
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
   .progress-bar-circle {
		 span {
			 font-size: 48px;
		 }
	 }
	
	* + .progress-bar-circle-title {
		margin-top: 20px;
	}
}
