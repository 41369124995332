/*
* Links
*/

// Standard link style
a {
	transition: $transition-base;

	&,
	&:focus,
	&:active,
	&:hover {
		text-decoration: $link-decoration;
	}

	@include link($link-color, $link-hover-color);

	&[href*='tel'],
	&[href*='mailto'] {
		white-space: nowrap;
	}
}

.link-hover {
	color: $link-hover-color;
}

.link-press {
	color: $link-press-color;
}

.privacy-link {
}

* + .privacy-link {
	margin-top: 25px;
}


.brands {
	img {
		transition: .3s ease-out all;
	}
	
	&:hover {
	  img {
			opacity: .6;
		}
	}
}

.link-phone {
	font-size: 18px;
}

.link-inline {
	display: inline-flex;
	align-items: center;
	> .icon {
		position: relative;
		top: 1px;
		margin-right: 12px;
	}
	
	&-top {
		align-items: flex-start;
		> .icon {
			position: relative;
			top: 4px;
		}
	}
}