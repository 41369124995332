/**
*
* RD Navbar Classic
* =====================================================
*/
.rd-navbar-classic {
	// Static
	&.rd-navbar-static {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 10;
		//box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);

		[data-x-mode="true"] & {
			position: absolute;
		}

		
		.rd-navbar-main-outer {
			box-shadow: 0px 3px 4px 0px rgba($black, 0.09);
		}
		
		.rd-navbar-aside-outer,
		.rd-navbar-main-outer {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
		
		.rd-navbar-aside,
		.rd-navbar-main {
			max-width: $rd-navbar-static-width;
			margin-left: auto;
			margin-right: auto;
		}
		
		.rd-navbar-aside {
			padding: 10px 0;
		}
		
		.rd-navbar-main {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 33px 15px;
			background-color: $white;
			transition: .1s ease-out padding;
		}
		
		// RD Navbar Brand
		.rd-navbar-brand {
			width: 269px;
			img {
				margin-left: auto;
				margin-right: auto;
				width: auto;
				height: auto;
				max-width: 282px;
				max-height: 200px;
			}
		}
		
		// RD Navbar Main Element
		.rd-navbar-main-element {
			display: flex;
			align-items: center;
			width: 100%;
		}
		
		// RD Navbar Nav Wrap
		.rd-navbar-nav-wrap {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
		
		// RD Navbar Nav Left
		.rd-navbar-nav-left {
			padding-right: 120px;
		}
		
		// RD Navbar Nav Right
		.rd-navbar-nav-right {
			text-align: right;
			padding-left: 120px;
			padding-right: 98px;
		}
		
		// RD Navbar Panel
		.rd-navbar-panel {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -43px;
		}
		
		// RD Navbar Search
		.rd-navbar-search {
			position: absolute;
			right: 15px;
			top: 50%;
			
			.rd-navbar-search-toggle {
				transform: translateY(-50%);
			}
		}
		
		&.active {
			.rd-search {
				visibility: visible;
				opacity: 1;
			}
		}
		
		.rd-search {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100vh;
			width: 100vw;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			margin: 0;
			background-color: rgba($gray-800, .57);
			opacity: 0;
			visibility: hidden;
			transition: .3s;
			z-index: 2;
			
			.form-wrap {
				position: relative;
				margin: 220px 10px;
				width: 534px;
			}
			
			.form-label,
			.form-input {
				padding-left: 0;
				padding-top: 5px;
				padding-bottom: 5px;
				font-size: 30px;
				font-weight: 700;
				color: $white;
			}
			
			.form-input {
				padding-right: 75px;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid $white;
			}
			
			@at-root html {
				&.rd-search-active {
					overflow: hidden;
				}
				&.rd-navbar-fixed-linked.rd-search-active {
					overflow: visible;
				}
			}
			
		}
		
		.rd-search-form-close,
		.rd-search-form-submit {
			top: 50%;
			transform: translateY(-50%);
			transition: .3s ease-out all;
			
			&, &:focus, &:active {
				color: $white;
			}
			&:hover {
				color: $primary;
			}
		}
		.rd-search-form-close {
			margin-top: 4px;
			position: absolute;
			bottom: 0;
			width: 34px;
			font-size: 24px;
			right: 0;
			text-align: right;
			cursor: pointer;
			line-height: 0.7;
		}
		
		.rd-search-form-submit {
			position: absolute;
			font-size: 25px;
			bottom: 0;
			right: 25px;
			line-height: 0.7;
			padding: 0;
			border: none;
			outline: none;
			background-color: transparent;
		}
		
		
		
		.brand-logo {
			opacity: 1;
			transform: translateY(0);
			transition: .15s ease-out all;
		}
		
		.brand-logo-stuck {
			position: absolute;
			left: 50%;
			top: 43px;
			max-width: 87px;
			opacity: 0;
			transform: translate(-50%, -30px);
			transition: .15s ease-out all;
		}
		.rd-navbar-brand {
			.brand-logo-stuck {
				max-width: 87px;
				img {
					max-width: 87px;
				}
			}
		}
		
		.rd-nav-link {
			font-size: 14px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
		}
		
		.rd-menu {
			z-index: -41;
			border: none;
		}
		
		.rd-navbar-dropdown {
			margin-top: 32px;
			padding: 0;
			overflow: hidden;
		}

		
		.rd-navbar-megamenu {
			margin-top: 4px;
			padding-top: 100px;
			padding-left: 30px;
			padding-right: 30px;
			left: 15px;
			right: 15px;
			width: auto;
		}
		
		.rd-megamenu-title {
			max-width: 213px;
			font-family: $font-family-sec;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $primary;
			border-bottom: 1px solid $gray-100;
		}
		
		* + .rd-megamenu-list {
			margin-top: 22px;
		}
		
		.rd-megamenu-list-item + .rd-megamenu-list-item {
			margin-top: 15px;
		}
		
		.rd-dropdown-link,
		.rd-megamenu-list-link {
			font-size: 16px;
			letter-spacing: 0.06em;
			
			&, &:focus, &:active {
				color: $gray-800;
			}
			
			&:hover {
			  color: $primary;
			}
		}
		
		.rd-dropdown-link {
			padding-left: 20px;
			padding-top: 19px;
			padding-bottom: 19px;
			letter-spacing: 0;
			border-top: 1px solid $gray-1;
			
			&:hover {
				padding-left: 35px;
			}
		}
		
		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			position: fixed;
			border-bottom-color: nth($rd-navbar-border, 3);
			.rd-navbar-aside-outer {
				display: none;
			}
			
			.brand-logo {
				opacity: 0;
				transform: translateY(-30px);
				pointer-events: none;
			}
			
			.rd-navbar-dropdown {
				margin-top: 17px;
			}
			
			.rd-navbar-megamenu {
				padding-top: 40px;
			}
			
			.brand-logo-stuck {
				opacity: 1;
				transform: translate(-50%, 0);
			}
			
			.rd-navbar-main {
				padding: 17px 15px;
			}
			
			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
		}
	}
	
	// Fixed
	&.rd-navbar-fixed {
		.brand-logo {
			display: none;
		}
		
		
		
		.rd-navbar-search {
			.rd-search {
				width: 300px;
				left: auto;
				right: 0;
			}
			
			.rd-search-form-submit {
				@include link($gray-800, $primary-light);
			}
			
			.rd-search-form-close {
				display: none;
			}
		}
		
		.brand-logo {
			line-height: 0;
		}
		
		.brand-logo-stuck {
			max-width: 65px;
			img {
				max-width: 65px;
			}
		}
	}
}
