/**
*
* RD Navbar Modern
* =====================================================
*/

$nav-height: 80px;

.rd-navbar-modern {
	// Static
	&.rd-navbar-static {
		position: relative;
		z-index: 10;
		background-color: transparent;
		
		.rd-navbar-aside-outer,
		.rd-navbar-main-outer {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
		
		.rd-navbar-aside,
		.rd-navbar-main {
			max-width: $rd-navbar-static-width;
			margin-left: auto;
			margin-right: auto;
		}
		
		.rd-navbar-aside {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 15px;
		}
		
		.rd-navbar-main {
			max-width: calc(100vw - 100px);
			width: 1134px;
			position: relative;
			z-index: 1;
			display: flex;
			height: $nav-height;
			align-items: center;
			justify-content: space-between;
			padding: 23px 15px 23px 35px;
			background-color: $gray-700;
			transition: .3s ease-out width;
			
			&:before {
			  content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;
				right: 100%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: $nav-height/2 21px $nav-height/2 0;
				border-color: transparent $gray-700 transparent transparent;
			}
			
			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				bottom: 0;
				left: 100%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: $nav-height/2 0 $nav-height/2 21px;
				border-color: transparent transparent transparent $gray-700;
			}
			
		}
		
		// RD Navbar Brand
		.rd-navbar-brand {
			img {
				margin-left: auto;
				margin-right: auto;
				width: auto;
				height: auto;
				max-width: 106px;
			}
		}
		
		// RD Navbar Main Element
		.rd-navbar-main-element {
			display: flex;
			align-items: center;
			width: 100%;
		}
		
		// RD Navbar Nav Wrap
		.rd-navbar-nav-wrap {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
		
		.rd-nav-item {
			margin-right: 0;
		}
		
		.rd-nav-item + .rd-nav-item {
			margin-left: 30px;
		}
		
		// Extra large ≥1200px
		@include media-breakpoint-up(xl) {
			.rd-nav-item + .rd-nav-item {
				margin-left: 56px;
			}
		}
		
		.rd-nav-link {
			font-size: 14px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			
			&, &:focus, &:active {
				color: $white;
			}
			
			.opened &,
			&.active,
			&:hover {
			  color: $secondary-light;
			}
		}
		
		.rd-nav-item.focus .rd-navbar-submenu-toggle,
		.rd-nav-item.opened .rd-navbar-submenu-toggle,
		.rd-nav-item.active .rd-navbar-submenu-toggle,
		.rd-nav-item.focus .rd-nav-link,
		.rd-nav-item.opened .rd-nav-link,
		.rd-nav-item.active .rd-nav-link {
			color: $secondary-light;
		}
		
		.rd-navbar-search-toggle {
			&, &:focus, &:active {
				color: $white;
			}
			
			&:hover {
				color: $secondary-light;
			}
		}
		
		.rd-menu {
			top: 100%;
			margin-top: 26px;
		}
		
		.rd-navbar-dropdown {
			padding: 0;
			overflow: hidden;
		}
		
		.rd-dropdown-link {
			padding: 19px 20px;
			font-size: 16px;
			letter-spacing: 0;
			border-top: 1px solid $gray-1;
			
			&, &:focus, &:active {
				color: $gray-800;
			}
			
			&:hover {
				padding-left: 35px;
				color: $primary;
			}
		}
		
		.rd-navbar-megamenu {
			border: none;
			margin-top: 1px;
			z-index: -41;
			padding: 30px;
			left: 15px;
			right: 15px;
			width: auto;
		}
		
		
		.rd-megamenu-title {
			max-width: 213px;
			font-family: $font-family-sec;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $primary;
			border-bottom: 1px solid $gray-100;
		}
		
		* + .rd-megamenu-list {
			margin-top: 22px;
		}
		
		.rd-megamenu-list-item + .rd-megamenu-list-item {
			margin-top: 15px;
		}
		
		.rd-megamenu-list-link {
			font-size: 16px;
			letter-spacing: 0.06em;
			
			&, &:focus, &:active {
				color: $gray-800;
			}
			
			&:hover {
				color: $primary;
			}
		}
		
		.link-phone {
			font-size: 16px;
		}
		
		.rd-navbar-collapse {
			display: flex;
			align-items: center;
			
			* + .list-inline-xs {
				margin-top: 0;
				margin-left: 45px;
			}
		}
		
		// Search
		
		.rd-navbar-search {
			position: static;
		}
		
		.rd-search {
			position: absolute;
			top: 50%;
			right: 0;
			left: auto;
			bottom: auto;
			width: 100%;
			margin-top: 0;
			transform: translateY(-50%);
			
			.form-input,
			.form-label {
				font-size: 16px;
				color: $gray-500;
				padding-left: 27px;
			}
			
			.form-input {
				min-height: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 4px;
				background-color: $white;
				border: 1px solid $gray-1;
			}
			
			.form-label {
				top: 50%;
				transform: translateY(-50%);
			}
			
			.rd-search-form-submit {
				font-size: 24px;
				
				&, &:focus, &:active {
					color: $gray-800;
				}
				
				&:hover {
				  color: $primary;
				}
				
				&:before {
					font-family: 'fl-bigmug-line';
					content: '\e09c';
				}
			}
		}
		
		.list-contacts {
			> li {
				display: inline-flex;
			}
			
			.icon {
				margin-right: 10px;
			}
			
			a {
				@include link($gray-800, $primary-light)
			}
			
			@include spacing(15px, 5px);
			
			// Medium ≥576px
			@include media-breakpoint-up(sm) {
				@include spacing(30px, 5px);
				
				> li + li {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						height: 18px;
						border-left: 1px solid $gray-1;
					}
				}
			}
			
			// Extra large ≥1200px
			@include media-breakpoint-up(xl) {
				@include spacing(60px, 5px);
			}
		}
		
		[class*='ie-'] & .list-contacts {
			display: flex;
			align-items: center;
		}
		
		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			position: fixed;
			border-bottom-color: nth($rd-navbar-border, 3);
			.rd-navbar-aside-outer {
				display: none;
			}
			
			.rd-navbar-main-outer {
				background-color: $gray-700;
				
			}
			
			.rd-navbar-main {
				height: 60px;
				&:before,&:after {
				  content: '';
					display: none;
				}
			}
			
			.rd-navbar-dropdown {
				margin-top: 17px;
			}
			
			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
		}
	}
	
	// Fixed
	&.rd-navbar-fixed {
		.rd-navbar-panel,
		.rd-navbar-nav-wrap {
			box-shadow: $shadow-area-1;
		}
		.rd-navbar-brand  {
			img {
				max-width: 60px;
			}
		}
		
		.brand {
			line-height: 0;
		}
		
		.list-contacts {
			a { @include link($gray-800, $primary-light) }
		}
		.list-inline {
			> li { display: inline-block; }
		}
	}
}

.rd-navbar-static-linked {
	.page-header-modern + *,
	.page-header-modern + .swiper-modern { margin-top: -$nav-height/2; }
}