/*
* Footers
*/

/*
 * Footer Classic
*/

.footer-classic {
	h4 { text-transform: uppercase; }
	p { padding-right: 10px; }
	h4 + * { margin-top: 10px; }
	
	&.context-dark {
		a {
			@include link($white, $secondary);
		}
	}
	
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		h4 + * { margin-top: 21px; }
	}
}

.footer-classic-item { max-width: 240px; }

// Small <576px
@include media-breakpoint-down(xs) {
	.footer-classic-item {
		margin-left: auto;
		margin-right: auto;
	}
}

.footer-classic-bottom {
	padding: 18px 0;
	background-color: rgba($gray-900, .5);
}

/*
 * Footer Modern
*/
.footer-modern {
	padding: 12px 0;
	background-color: $gray-700;
	p.rights {
		a {
			@include link($gray-500, $primary-light)
		}
	}
}

/*
 * Footer Full width
*/
.pre-footer-full-width {
	color: $gray-800;
	
	h4 {
		text-transform: uppercase;
		& + * {
			margin-top: 8px;
		}
	}
	
	.list {
		> li {
			& + li {
				margin-top: 5px;
			}
		}
		
		a {
			@include link($gray-800, $primary-light)
		}
	}
	
	.form-input {
		background-color: transparent;
		border: 1px solid #ebebeb;
	}
	
	* + .rd-form { margin-top: 10px; }
	* + .list-inline { margin-top: 10px; }
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		h4 {
			& + * {
				margin-top: 21px;
			}
		}
		
		.link-tel {
			font-size: 16px;
		}
	}
}

.footer-full-width {
	padding: 20px 0;
	
	.list-inline {
		@include spacing(10px, 6px);
		text-align: center;
		text-transform: uppercase;
	}
	
	a {
		@include link($gray-500, $secondary-light);
	}
	
	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.list-inline {
			@include spacing(30px, 6px);
		}
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		.list-inline {
			text-align: left;
			letter-spacing: 0.05em;
			@include spacing(55px, 6px);
		}
		
		p.rights {
			text-align: right;
		}
	}
}