/*
* Pagination custom
*/

.pagination {
	font-size: 0;
	line-height: 0;
	@include group(10px);
}

.page-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-weight: 700;
}

// Base styles
.page-link {
	display: inline-block;
	min-width: $pagination-padding-y*2 + $pagination-border-width*2 + $pagination-line-height;
	padding: $pagination-padding-y $pagination-padding-x;
	border: $pagination-border-width solid $pagination-border-color;
	font-size: $pagination-font-size;
	line-height: ($pagination-line-height / $pagination-font-size);
	background-color: $pagination-bg;
	color: $pagination-color;
	transition: $transition-base;
}

// Hover & Focus & Active State
.page-link {
	&:hover,
	&:focus,
	&:active {
		color: $pagination-hover-color;
		background-color: $pagination-hover-bg;
		border-color: $pagination-hover-border-color;
	}
}

// Active State
.page-item.active > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-active-color;
		background-color: $pagination-active-bg;
		border-color: $pagination-active-border-color;
	}
}

// Disabled State
.page-item.disabled > .page-link {
	&,
	&:hover,
	&:focus,
	&:active {
		color: $pagination-disabled-color;
		background-color: $pagination-disabled-bg;
		border-color: $pagination-disabled-border-color;
	}
}

.page-item-control {
	.icon::before {
		font-family: $fa;
		font-size: 11px;
		line-height: $pagination-line-height;
	}
}

// Prev control
.page-item-control:first-child {
	.icon::before {
		content: '\f053';
		margin-left: -1px;
	}
}

// Next control
.page-item-control:last-child {
	.icon::before {
		content: '\f054';
		margin-right: -1px;
	}
}


/*
 * Pagination media
*/
.pagination-media {
	
	&-wrap {
		* + & { margin-top: 45px; }
	}
	
	text-align: center;
	@include spacing(5px, 15px);
	> li {
		display: inline-block;
	}
	
	a,
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 31px;
		height: 31px;
		
		&,
		&:focus,
		&:active,
		&.pagination-media-disabled,
		&.pagination-media-disabled:hover {
			color: #aaaaaa;
			background-color: transparent;
		}
		
		&.pagination-media-active,
		&:hover {
			color: $white;
			background-color: $primary-light;
		}
		
		&.pagination-media-disabled {
			cursor: auto;
		}
		
		&.pagination-media-active {
			cursor: no-drop;
		}
	}
	
 
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		&-wrap {
			* + & { margin-top: 50px; }
		}
	}
}