/*
*
* Slick Carousel
*/

//
// Base styles
//
/*rtl:begin:ignore*/

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

// Appearance styles
// --------------------------------------------------

.slick-list {
	.slick-loading & {
		background: $white slick-image-url("../images/ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: rgba($black, .6);
	color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 9;

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
}

.slick-prev:before, .slick-next:before {
	font-family: $slick-font-family;
	font-size: 20px;
	line-height: 1;
	color: $slick-arrow-color;
	opacity: $slick-opacity-default;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: 0;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: 0;
	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-slider {
	//margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -45px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		height: 20px;
		width: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: block;
			height: 20px;
			width: 20px;
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
				&:before {
					opacity: $slick-opacity-on-hover;
				}
			}
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: $slick-dot-character;
				width: 20px;
				height: 20px;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				line-height: 20px;
				text-align: center;
				color: $slick-dot-color;
				opacity: $slick-opacity-not-active;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active button:before {
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}

.slick-main-title {

}

// Large ≥992px
@include media-breakpoint-up(lg) {
 .slick-main-title {
	 line-height: 1;
 }
}

.slick-slider-main-wrap {
	position: relative;
}

.slick-slider-main {
	transition: .1s ease-out opacity;
}

* + .slick-slider-main-wrap {	margin-top: 35px; }


// Slick Slider Project Main
.slick-slider-project-main {
	padding: 0 0 55px;
	transition: .1s ease-out opacity;
	
	.slide {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	img {
		width: 100%;
		object-fit: cover;
	}
	
	.slick-arrow {
		width: 48px;
		height: 46px;
		font-size: 16px;
		line-height: 46px;

		
		top: auto;
		bottom: 0;
		transform: translateY(0);
		
		&:before {
			font-family: $line-icons;
			font-size: inherit;
			line-height: inherit;
			color: inherit;
			opacity: 1;
		}
		
		&, &:focus, &:active {
			color: $gray-400;
			background-color: rgba($black, .05);
		}
		
		&:hover {
			color: $black;
			background-color: $secondary-light;
		}
		
		transition: .3s ease-out all;
	}
	
	.slick-prev {
		&:before { content: '\ec3b'; }
	}
	.slick-next {
		&:before { content: '\ec3c'; }
	}
	
	// Medium ≥576px
	@include media-breakpoint-down(xs) {
		.slick-prev {
			left: calc(50% - 30px);
			transform: translateX(-50%);
		}
		.slick-next {
			left: calc(50% + 30px);
			transform: translateX(-50%);
		}
	}
	
	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		padding: 0 45px;
		.slick-arrow {
			top: 50%;
			bottom: auto;
			margin: 0;
			transform: translateY(-50%);
			
		}
	}
	
	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
		.slide {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}




// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .slick-slider-main-wrap {	margin-top: 80px; }
}

// Project wrap
.slick-slider-project-wrap {
	position: relative;
}

* + .slick-slider-project-wrap {	margin-top: 35px; }
.slick-slider-project-filter + .slick-slider-project-wrap { margin-top: 20px; }

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	* + .slick-slider-project-wrap {	margin-top: 50px; }
	.slick-slider-project-filter + .slick-slider-project-wrap { margin-top: 30px; }
}

.slick-slider-filter {
	@include spacing(15px, 10px);
	> li {
		display: inline-block;
	}
	a {
		font-size: 16px;
		text-transform: uppercase;
		@include link($gray-500, $gray-800);
	}
	
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		text-align: right;
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		@include spacing(42px, 10px);
	}
}

/*
 * Slick filtering project
*/
.slick-slider-project-filter {
	text-align: center;
	@include spacing(15px, 5px);
	
	> li { display: inline-block; }
	
	a {
		font-size: 16px;
		text-transform: uppercase;
		@include link($gray-500, $primary-light);
	}
}

* + .slick-slider-project-filter { margin-top: 15px; }

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.slick-slider-project-filter {
		@include spacing(42px, 10px);
		> li + li {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: 80%;
				border-left: 1px solid #d4d9e0;
			}
		}
	}
}

/*
 * Slick Fraction
*/
.slick-fraction {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
	color: $gray-800;
}

.custom-slick-arrows {
	position: relative;
	margin-top: 15px;
	width: 158px;
	height: 48px;
	margin-left: auto;
	margin-right: auto;
	
	.slick-arrow {
		top: 0;
		width: 54px;
		height: 48px;
		line-height: 48px;
		color: $gray-500;
		background: transparent;
		border: 3px solid $gray-1;
		transform: translate(0, 0);
		transition: .3s ease-out all;
		
		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			
		  font-family: $line-icons;
			font-size: 25px;
			line-height: 48px;
			
			opacity: 1;
			color: inherit;
			transform: translate(-50%, -50%);
			
		}
		
		&:hover {
			color: $gray-800;
		  border-color: $secondary;
		}
	}
	
	.slick-prev {
		&:before {
		  content: '\ec3b';
		}
	}
	
	.slick-next {
		&:before {
		  content: '\ec3c';
		}
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		position: absolute;
		top: 0;
		left: 0;
		margin-top: 0;
	}
}


.slick-slider-projects {
	margin-bottom: 30px;
}

.slick-slider-projects-thumbs {
	padding-left: 50px;
	padding-right: 50px;
	
	
	.item {
		cursor: pointer;
		&.slick-current,
		&:hover {
		  img {
				border-color: $secondary;
			}
		}
	}
	
	img {
		display: block;
		width: 171px;
		height: 126px;
		object-fit: cover;
		margin-left: auto !important;
		margin-right: auto !important;
		border: 4px solid $white;
		transition: .3s ease-out all;
	}
	
	.slick-arrow {
		width: 35px;
		height: 35px;
		font-size: 36px;
		opacity: 1;
		transition: .3s ease-out all;
		
		&:before {
		  font-family: $line-icons;
			font-size: inherit;
			color: inherit;
			opacity: 1;
		}
		
		&, &:focus, &:active {
			color: $gray-500;
			background-color: transparent;
		}
		
		&:hover {
		  color: $gray-800;
		}
	}
	
	.slick-prev {
		&:before {
		  content: '\ec3b';
		}
	}
	
	.slick-next {
		&:before {
		  content: '\ec3c';
		}
	}
}

/*
 * Slick Single Project
*/

.slick-slider-single-project {
	
	.desktop & {
		user-select: auto;
	}
	
	&-arrows {
		position: relative;
		text-align: center;
		margin-bottom: -10px;
		
		.slick-arrow {
			position: relative;
			margin-left: 10px;
			margin-right: 10px;
			margin-bottom: 10px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 192px;
			height: 50px;
			line-height: 1.2;
			
			font-family: $font-family-base;
			font-size: 14px;
			letter-spacing: 0.05em;
			font-weight: 700;
			text-transform: uppercase;
			
			border: 2px solid;
			
			transform: translate(0, 0);
			
			transition: .3s ease-out all;
			
			&:before {
			  font-size: inherit;
				color: inherit;
			}
			
			&.slick-disabled {
				cursor: no-drop;
			}
			
			&, &:focus, &:active, &.slick-disabled, &.slick-disabled:hover {
				color: $gray-800;
				background: transparent;
				border-color: #ebebeb;
				opacity: 1;
				&:before {
					opacity: 1;
				}
			}
			
			&:hover {
				color: $white;
				border-color: $gray-700;
				background-color: $gray-700;
			}
		}
		
		.slick-prev {
			&:before {
				content: 'Previous Project';
			}
		}
		
		.slick-next {
			&:before {
				content: 'Next Project';
			}
		}
		
		* + & { margin-top: 30px; }
	}
	

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		&-arrows {
			* + & { margin-top: 70px; }
		}
	}
}