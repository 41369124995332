/**
* Template Style
*
* [Table of contents]
*   1. Main Styles
*     1.1 Bootstrap Function
*     1.2 Bootstrap Variables
*     1.3 Bootstrap Mixins
*     1.4 Custom Variables
*     1.5 Reset
*
*   2. Components
*     2.1 Type Custom
*     2.2 Brands
*     2.3 Links
*     2.4 Blocks
*     2.5 Boxes
*     2.6 Blog
*     2.7 Group
*     2.8 Units
*     2.9 Lists
*     2.10 Images
*     2.11 Icons
*     2.12 Tables
*     2.13 Dividers
*     2.14 Buttons
*     2.15 Forms
*     2.16 Posts
*     2.17 Projects Box
*     2.18 Quotes
*     2.19 Thumbnails
*     2.20 Breadcrumbs
*     2.21 Pagination Custom
*     2.22 Snackbars
*     2.23 Footers
*
*   3. Helpers
*     3.1 Page Layout
*     3.2 Text Styling
*     3.3 Offsets
*     3.4 Sections
*     3.5 Grid Modules
*     3.6 Backgrounds
*     3.7 Utilities Custom
*
*   4 Plugins
*     4.1 Animate
*     4.2 Preloader
*     4.3 Ui To Top
*     4.4 Rd Navbar
*     4.5 Swiper
*     4.6 Google Map
*     4.7 Rd Search
*     4.8 Isotope
*     4.9 Nav Custom
*     4.10 Card Custom
*     4.11 Tooltip Custom
*     4.12 Counter
*     4.13 Progress Bars
*     4.14 Jquery Circle Progress
*     4.15 Timecircles
*     4.16 Owl Carousel
*     4.17 Lightgallery
*     4.18 Slick
*     4.19 Material Parallax
*     4.20 Video
**/


// Main styles
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import "variables-custom";
@import "mixins";
@import "reset";

// Components
@import "components/type-custom";
@import "components/brand";
@import "components/links";
@import "components/blocks";
@import "components/boxes";
@import "components/blog";
@import "components/groups";
@import "components/units";
@import "components/lists";
@import "components/images";
@import "components/icons";
@import "components/tables";
@import "components/dividers";
@import "components/buttons";
@import "components/forms";
@import "components/posts";
@import "components/projects-box";
@import "components/quotes";
@import "components/thumbnails";
@import "components/breadcrumbs";
@import "components/pagination-custom";
@import "components/snackbars";
@import "components/footers";

// Helpers
@import "helpers/page-layout";
@import "helpers/text-styling";
@import "helpers/offsets";
@import "helpers/sections";
@import "helpers/grid-modules";
@import "helpers/backgrounds";
@import "helpers/utilities-custom";

// Plugins
@import "plugins/animate";
@import "plugins/preloader";
@import "plugins/ui-to-top";
@import "plugins/rd-navbar";
@import "plugins/swiper";
@import "plugins/google-map";
@import "plugins/rd-search";
@import "plugins/isotope";
@import "plugins/nav-custom";
@import "plugins/card-custom";
@import "plugins/tooltip-custom";
@import "plugins/counter";
@import "plugins/progress-bars";
@import "plugins/jquery-circle-progress";
@import "plugins/timecircles";
@import "plugins/owl-carousel";
@import "plugins/lightgallery";
@import "plugins/slick";
@import "plugins/material-parallax";
@import "plugins/video";
